class MapThermalData {
    constructor () {
        this.r = 0
        this.g = 0
        this.b = 0
        this.isRGB = false
        this.h = 0
        this.s = 0
        this.l = 0
        this.isDefault  = false
    }
}

export default MapThermalData;
