<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 100px);">
    <div class="tabBox one" style="border: none; width: 22%;margin-right: 20px;">
      <div class="liveMap-detail">
        <div class="liveMap-detail-info" :style="{ 'height': (typeChangeMapShowHeight - 180) + 'px' }">
          <h2>상세정보</h2>
          <div class="liveMap-detail-info-header">
            <span>{{ $t("livemap-data-way-waypointId") }}</span>
            <h2>{{waypointInfo.waypointId}}</h2>
          </div>
          <div class="liveMap-detail-info-data">
            <h4>{{ $t("livemap-data-way-startdate") }}</h4>
            <h2>{{ moment(startDate).format("YYYY.MM.DD HH:mm")}}</h2>
          </div>
          <ul>
            <li>
              <span>{{ $t("device-data-manager-dept-name") }}</span>
              <h4>{{ waypointInfo.userDepartmentName}}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-manager-operator") }}</span>
              <h4>{{ waypointInfo.userName}}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-device-name") }}</span>
              <h4>{{ deviceName }}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-model-name") }}</span>
              <h4>{{ deviceModelName }}</h4>
            </li>
          </ul>
        </div>
        <button class="point medium" style="width: 100%;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;" @click="backToLiveMapList">{{ $t("livemap-data-back-to-list") }}</button>
      </div>
    </div>
    <div class="map" style="width: calc(78% - 45px);height: calc(100% + 50px);position: absolute;right: 0;bottom: 0">
      <Map ref="map" style="width : 100%; height: 100%; position: absolute; z-index:1; -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none" :loadMapName="loadMap" />
      <!-- 임시 화선 데이터 조정 바 -->
      <div class="thermal-container" style="-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none">
        <div class="box thermal-info flex">
          <div style="width: 100px; margin-right: 5px;">
            <span>{{ waypointLogInfo.thermalType == undefined ? '알수 없음' : waypointLogInfo.thermalType }}</span>
          </div>
          <div style="width: 50px;">
            <span style="padding-right: 10px;">{{ waypointLogInfo.thermalMinTempe == undefined ? '0.0' : waypointLogInfo.thermalMinTempe }}</span>
          </div>
          <img style="width: 100%; height: 100%" :src="thermalImg"/>
          <div style="width: 50px;">
            <span style="padding-left: 10px;">{{ waypointLogInfo.thermalMinTempe == undefined ? '0.0' : waypointLogInfo.thermalMaxTempe }}</span>
          </div>
        </div>
        <div class="box thermal-show-color flex" style="cursor: pointer;" @click="onThermalColorInfoShow" :style="[{'background-color': thermalShowColor}]">
          {{ thermalShowColor == null ? 'NO' : ''}}
        </div>

        <div class="box thermal-color-container flex" v-if="isThermalColorInfoShow" >
          <div style="width: 100%; font-size: 2rem;height: 30px;position: absolute;">
            <label class="radioBtn">
              <input type="radio" name="status" :value="'black-white'" v-model="thermalShowSelect" /> 흑백
            </label>
            <label class="radioBtn">
              <input type="radio" name="status" :value="'color'" v-model="thermalShowSelect"/> 칼라
            </label>
          </div>

          <div v-if="thermalShowSelect == 'black-white'" class="thermal-color-slide-container">
            <input background-color="red" type="range" class="thermal-slider" id="temp" name="temp" min="0" max="255" list="tickmarks" v-model="blackValue">
          </div>

          <div v-if="thermalShowSelect == 'color'" class="thermal-color-select-container">
            <div class="thermal-color-select-box">
              <div class="thermal-color-div" v-for="data in thermalSelectColorArray" :key="data.color"  :style="{'background-color': data.color}" @click="onSelectColor(data.value)"  :class="{'thermal-color-selected': selectColorValue == data.value }"/>
            </div>
          </div>
          <!--          {{colors.rgba}}-->
          <button class="point btn-confirm" @click="thermalImageChange" >확인</button>
          <button class="margin6 btn-default" @click="thermalImageDefault" >기본</button>
        </div>
      </div>
    </div>
  </div>

</template>

<style>
.liveMap-detail-info > h2{font-size: 1.4rem;font-weight: bold;margin-bottom: 25px;}

.liveMap-detail-info-header{margin-bottom: 15px;}
.liveMap-detail-info-header > h2{font-size: 1.8rem;font-weight: bold;line-height: 28px;}
.liveMap-detail-info-header > span{color: #969696;font-size: 1.2rem;margin-bottom: 10px;display: block;}

.liveMap-detail-info-data{background: #f7f7f7;padding: 10px;border-radius: 5px;display: flex;justify-content: space-between;align-items: center;margin-bottom: 30px;}
.liveMap-detail-info-data > h4{font-size: 1.2rem;color: #969696;}
.liveMap-detail-info-data > h2{margin: 0;font-size: 1.4rem;}

.liveMap-detail-info > ul > li{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;}
.liveMap-detail-info > ul > li > span{width: 100px;background: #eee;border-radius: 10px;text-align: center;color: #969696;padding: 3px;}
.liveMap-detail-info > ul > li > h4{font-size: 1.6rem;font-weight: 500;}
</style>

<script>
import moment from "moment";
import {fetchWaypointInfo} from  "@/api/missionWaypoint";
import {fetchWaypointLogInfo} from  "@/api/missionWaypointLog";
import { fetchEvent } from "@/api/event";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapThermalData from "@/components/map/data/MapThermalData";


import { fetchLocation } from "@/api/location";
import {getUrlLiveMapThumbnail} from "@/api/contents";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel, getRGBtoHSV} from "@/util/common";
import {getThermalViewNameToImage} from "@/api/device";

export default {
  components: { Map, },
  props: {
  },
  data() {
    return {
      index: 0,
      moment: moment,
      waypointInfo : {},
      waypointLogInfo : {},
      waypointLocationList: [],
      mapWidth: 0,
      offset : -1,
      typeChangeMapShowHeight: 0,
      loadMap : 'kakao',

      blackValue : 0,
      isThermalColorInfoShow : false,
      thermalImg : null,
      eventId: null,
      waypointId: null,
      refId:null,
      contentsId: null,
      deviceModelName:null,
      deviceName: null,
      startDate: null,

      selectColorValue : null,
      thermalShowSelect : 'color',
      thermalShowColor : null,

      thermalSelectColorArray : [
        {color : '#ff0000', value : 0},
        {color : '#ff8000', value : 30},
        {color : '#ffff00', value : 60},
        {color : '#80ff00', value : 90},
        {color : '#00ff00', value : 120},
        {color : '#00ff80', value : 150},
        {color : '#00ffff', value : 180},
        {color : '#0080ff', value : 210},
        {color : '#0000ff', value : 240},
        {color : '#8000ff', value : 270},
        {color : '#ff00ff', value : 300},
        {color : '#ff0080', value : 330},
      ]
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });

    this.mapWidth = window.innerWidth - 322;
    this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    window.addEventListener('resize', this.handleResize);

    this.eventId = this.$route.params.eventId;
    this.waypointId = this.$route.query.waypointId;
    this.refId = this.$route.query.refId;
    this.contentsId = this.$route.query.contentsId;
    this.deviceModelName = this.$route.query.deviceModelName;
    this.deviceName = this.$route.query.deviceName;
    this.startDate = this.$route.query.startDate;
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    selectColorValue(value){
      if(value == null) {
        this.thermalShowColor = null;
      }
    }
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  mounted() {
    this.getEvent();
    this.getWaypointInfo();
    this.getWaypointLogInfo();
    this.interval = setInterval(() => {
      this.getLocation();
    }, 2000);
  },
  methods: {
    onThermalColorInfoShow(){
      this.isThermalColorInfoShow = !this.isThermalColorInfoShow
    },
    thermalImageChange(){
      var showColor = null;
      this.isThermalColorInfoShow = !this.isThermalColorInfoShow;

      let data = new MapThermalData();
      data.isRGB = this.thermalShowSelect != "color";
      if(data.isRGB) {
        let blackValue = this.blackValue % 256;

        let colorHsv = getRGBtoHSV(blackValue, blackValue,blackValue);
        data.l = colorHsv.l;
        data.s = colorHsv.s;

        let hax = (blackValue).toString(16);
        showColor = '#' + hax + hax + hax;
      } else {
        if(this.selectColorValue != null) {
          var index = this.thermalSelectColorArray.findIndex((item) => item.value == this.selectColorValue)
          if(index != -1) {
            showColor = this.thermalSelectColorArray[index].color
          }
        }
        data.h = this.selectColorValue;
      }

      this.thermalShowColor = showColor;
      this.$refs.map.onOverlayColorChange(MapDataType.ID_LIVE_MAP, data);
    },
    thermalImageDefault(){
      this.selectColorValue = null;
      this.thermalShowColor = null;

      this.isThermalColorInfoShow = !this.isThermalColorInfoShow;

      let data = new MapThermalData();
      data.isDefault = true;
      this.$refs.map.onOverlayColorChange(MapDataType.ID_LIVE_MAP,data);
    },
    onSelectColor(value){
      this.selectColorValue = value;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.onMapCenter();
        this.setMapSetting();
      })
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    setMapSetting(){
      this.$refs.map.onShowButtonTypeChange();
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    getThermalImg(type){
      return getThermalViewNameToImage(type)
    },
    getWaypointInfo(){
      fetchWaypointInfo(this.waypointId).then((res) => {
        if (res.data.result == 0) {
          this.waypointInfo = res.data.data;
        }
      })
    },
    getWaypointLogInfo(){
      fetchWaypointLogInfo(this.refId).then((res) => {
        if (res.data.result == 0) {
          this.waypointLogInfo = res.data.data;
          this.thermalImg = this.getThermalImg(this.waypointLogInfo.thermalType)
        }
      })
    },
    getLocation() {
      // let i = 0;
      if(this.refId == null) {
        return;
      }

      let params = {
        refId: this.refId,
        eventId : this.eventId,
        contentsId : this.contentsId,
        offsetIndex : this.offset,
      };

      fetchLocation(params).then((res) => {
        var content =  res.data.data.content;
        let sort = content.sort((a,b) => {return a.index - b.index})
        if(sort.length > 0) {
          this.offset = sort[sort.length - 1].index;
          let mapDataType = MapDataType.ID_LIVE_MAP;
          this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)
          sort.forEach((item) => {
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = item.index;
            mapMarkerData.lng = item.lng;
            mapMarkerData.lat = item.lat;
            mapMarkerData.rotate = item.azimuth;
            mapMarkerData.thumbnail = getUrlLiveMapThumbnail(item.liveMapFileId);
            mapMarkerData.liveMapBounds = {
              leftBottom : {lat : item.latLeftBottom, lng : item.lngLeftBottom },
              rightTop : {lat : item.latRightTop, lng : item.lngRightTop }
            }
            if(this.$refs.map.onIsMarker(mapDataType, item.index ) ) {
              this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
              this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
          })
          //http://localhost:9000/api/v1/thumbnail/file/FL-23b7556b-ebaf-4571-aee2-51c5d513b86
        }
      });
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleResize(){
      this.mapWidth = window.innerWidth - 322;
      this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    },
    handleTab(id) {
      this.visible = id;
    },
    backToLiveMapList(){
      this.$router.go(-1)
      //this.$emit("back-list")
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
  },
};
</script>
<style>
#temp.thermal-slider {
  -webkit-appearance: none;
  appearance: slider-horizontal;
  width: 370px;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 0px;
  outline: none;
  opacity: 1.0;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
.thermal-slider:hover {
  opacity: 1;
}
.thermal-slider::-webkit-slider-runnable-track {
  border-radius: 1rem;
  background: linear-gradient(90deg, rgba(0,0,0,100) 0%, rgba(255,255,255,100) 100%);
}
.thermal-slider{
  accent-color: black;
}

.thermal-container {
  position: absolute;
  top: 20px;
  right: 120px;
  width: 400px;
  height: 340px;
  z-index: 2000;
}

.thermal-container > .thermal-info {
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 30px;
  width: 100%;
  left: 0px;
  text-align: right;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "INTER", "Noto", sans-serif;
}

.thermal-container > .thermal-show-color {
  position: absolute;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  top: 0px;
  right: -40px;
  width: 30px;
  height: 30px;
  margin: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.thermal-container > .thermal-color-container {
  position: absolute;
  width: 100%;
  height: 135px;
  top: 45px;
  padding: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.thermal-container > .thermal-color-container > .thermal-color-slide-container {
  width: 410px;
  height: 30px;
  position: absolute;
  top: 50px;
}

.thermal-container > .thermal-color-container > .thermal-color-slide-container> .slide {
  width: 370px !important;
  height: 30px;
}

.thermal-container > .thermal-color-container > .thermal-color-select-container {
  position: absolute;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 50px;
  width: 377px;
  top: 45px;
}

.thermal-container > .thermal-color-container > .thermal-color-select-container> .thermal-color-select-box {
  height: 50px;
  width: 422px;
  top: 55px;
}



.thermal-container > .thermal-color-container > .thermal-color-select-container> .thermal-color-select-box > .thermal-color-div {
  width: 30px !important;
  height: 30px;
  margin-right: 5px;
  display: inline-block;
}

.thermal-container > .thermal-color-container > .thermal-color-select-container> .thermal-color-select-box > .thermal-color-selected {
 border: 2px solid #2c2c2c;
}


.thermal-container > .thermal-color-container > .btn-confirm {
  position: absolute;
  width: 70px;
  bottom: 5px;
  right: 10px;
  text-align: center;
}

.thermal-container > .thermal-color-container > .btn-default {
  position: absolute;
  width: 70px;
  bottom: 5px;
  right:100px;
  text-align: center;
  font-weight: 400;
  color: white;
  background: black;
  border: 1px solid white;
  border-radius: 4px;
}

.thermal-container > .thermal-color-container > .btn-default:hover {
  background: grey;
}

div.vc-slider-swatches {
  display: none;
}

datalist {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: column;
  justify-content: space-between;
  writing-mode: bt-lr;
  /*width: 200px;*/
  margin-left: 12px;
}

option {
  padding: 0;
}

</style>
